
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        













































































































































































































$bp: l;

.flexible-testimonials {
  --item-width: 32rem;
  --item-height: auto;
  --max-lines: 6;

  overflow: hidden;
  padding-top: var(--spacing-m);
  padding-bottom: var(--spacing-m);
  background: rgba($c-lighter-grey, 40%);

  @include mq($bp) {
    --max-lines: 4;
    --slide-gap: 4rem;
    --item-width: 44rem;
  }
}

.flexible-testimonials-wrapper {
  position: relative;
}

.flexible-testimonials__title {
  @extend %text-center;
}

.flexible-testimonials__carousel {
  margin-top: var(--spacing-m);

  &.carousel {
    --scrollbar-width: 0;
  }

  ::v-deep .carousel__item {
    width: var(--item-width);
    max-width: 80vw;
    height: var(--item-height);
  }

  ::v-deep .carousel__controls {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: var(--spacing-m);
  }

  @include mq($bp) {
    ::v-deep .carousel__controls {
      justify-content: flex-end;
    }
  }
}

.flexible-testimonials__item {
  @extend %border-radius-small;

  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 2.8rem 1fr;
  width: 100%;
  height: 100%;
  padding: var(--spacing-m);
  background: $c-white;
  border: 0.1rem solid $c-light-grey;

  .flexible-testimonials__modal & {
    padding: 0 0 var(--spacing-m);
    border: 0;
  }

  @include mq($bp) {
    grid-template-columns: 3.8rem 1fr;
  }
}

.flexible-testimonials__item__title {
  @extend %ff-default;

  grid-row: 1/2;
  grid-column: 2/3;
  align-self: end;
  font-size: 1.4rem;
  text-align: right;

  address {
    @extend %fw-light;

    display: inline-block;
    margin-left: 0.8rem;
    color: $c-light-grey;
    font-style: normal;
  }
}

.flexible-testimonials__item__text {
  --lh: 2.8rem;

  position: relative;
  grid-row: 2/3;
  grid-column: 1/3;
  overflow: hidden;
  margin: 1.6rem 0;
  padding-bottom: 1.6rem;
  font-size: 1.8rem;
  line-height: var(--lh);

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 0.1rem solid $c-lighter-grey;
  }

  .flexible-testimonials__modal & {
    padding-bottom: 2.4rem;
    border-bottom: 0.1rem solid $c-lighter-grey;

    &::after {
      content: none;
    }
  }

  @include mq($bp) {
    margin-top: 2.4rem;
  }
}

.flexible-testimonials__item__text__inner {
  .shortened & {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: var(--max-lines);
  }

  .flexible-testimonials__modal & {
    display: block;
  }
}

.flexible-testimonials__item__icon {
  grid-row: 1/2;
  grid-column: 1/2;
  width: 100%;
  height: auto;
  color: $c-mustard;
}

.flexible-testimonials__item__button {
  grid-row: 3/4;
  grid-column: 1/3;
  color: $c-mustard;

  ::v-deep .action__label {
    @extend %fw-bold;
  }

  &::after {
    @include get-all-space;

    content: '';
  }
}

.flexible-testimonials__progress {
  position: absolute;
  // right padding + 2 buttons + spacings
  right: calc(var(--wrapper-padding) + 13rem);
  bottom: 2.2rem;
  display: none;
  width: calc(100% - calc(calc(var(--wrapper-padding) * 2) + 13rem));
  height: 0.2rem;
  background: $c-medium-grey;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: $c-mustard;
    transform: scaleX(var(--progress));
    transform-origin: left;
    transition: transform 0.2s;
    will-change: transform;
  }

  @include mq($bp) {
    .flexible-testimonials__carousel:has(.can-scroll) + & {
      display: block;
    }
  }
}

.flexible-testimonials__modal {
  padding-top: var(--spacing-m);
  padding-bottom: var(--spacing-m);

  @include mq($bp) {
    ::v-deep .modal-inner {
      max-width: calc(var(--item-width) + 10rem);
    }
  }
}
