
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































































































































































/* stylelint-disable scss/double-slash-comment-empty-line-before */

.modal {
  --modal-padding-inner: var(--spacing-m);
  --modal-c-bg: #{$c-white};

  @include get-all-space;

  position: fixed;
  z-index: 1000;
  display: flex;
  align-items: center;
  overflow-y: scroll;

  &::after {
    @include get-all-space;

    content: '';
    position: fixed;
    background: rgba($c-dark-grey, 90%);
  }
}

.modal-inner {
  @extend %border-radius-small;

  position: relative;
  z-index: 1;
  width: 100%;
  margin: auto;
  padding-right: var(--modal-padding-inner);
  padding-left: var(--modal-padding-inner);
  background: var(--modal-c-bg);
}

.modal__close {
  top: 0;
  width: fit-content;
  margin-top: calc(var(--modal-padding-inner) * 0.25);
  margin-right: calc(var(--modal-padding-inner) * -0.75);
  margin-left: auto;
}
